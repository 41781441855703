<template>
 <div id="page-container" class="">
  <div class="content-wrap pb-">
   <PbxDashboardOutbound
    :key="periodSelector"
    v-model:selector="periodSelector"
    @button-selector="(...args) => getSelectedButton(...args)"
    :extensions="extensions"
    :urlType="urlType"
    :periodSelector="this.periodSelector"
    @timeSelector="setTimeSelected($event)"
    @all-selected="allSelected($event)"
    :buttonSelected="this.buttonSelected"
    :textMonth="this.textMonth"
    :timeSelected="this.timeSelected"
    @showWaitingDuration="setCharts($event)"
    :dataPbxDay="dataPbxDay"
    :elementLevelData="elementLevelData"
    :elementLevelDataRange="elementLevelDataRange"
    @waitingTimeRange="waitingTimeRange = $event"
    :waitingTimeRange="waitingTimeRange"
    :outgoingCallsData="extensionsData"
    :showDetail="showDetail" />
   <!-- <div
    v-if="
     !Object.keys(dataPbx).length &&
     !dataElementsDuration.length &&
     !Object.keys(elementLevelDataRange).length &&
     elementLevelDataRange.labels &&
     !elementLevelDataRange.labels.length
    "
   >
    <EmptyStateDashboard />
   </div> -->
  </div>
  <div
   class="flex flex-col"
   v-if="tableData && Object.keys(tableData).length > 0">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-6">
     <!-- <div class="flex items-center gap-6">
      <div class="rounded-md py-5">
       <p class="text-sm text-gray-600 font-bold">{{ $t("timeRange") }}</p>
       <div class="flex items-center gap-2">
        <input v-model="timeRange" type="range" id="vol" name="vol" min="5" max="100" step="5" />
        <span class="text-xs text-gray-500 font-medium"> {{ timeRange }}(s) </span>
       </div>
      </div>
      <div class="rounded-md py-5">
       <p class="text-sm text-gray-600 font-bold">Time gap</p>
       <div class="flex items-center gap-2">
        <input v-model="timeGap" type="range" id="vol" name="vol" min="5" max="20" step="5" />
        <span class="text-xs text-gray-500 font-medium"> {{ timeGap }}(s) </span>
       </div>
      </div>
      <div class="rounded-md py-5">
       <p class="text-sm text-gray-600 font-bold">{{ $t("columnsData") }}</p>
       <div class="flex items-center gap-2">
        <input
         v-model="numberOfColumns"
         type="range"
         id="vol"
         name="vol"
         min="1"
         max="5"
         step="1"
        />
        <span class="text-xs text-gray-500 font-medium"> {{ numberOfColumns }} </span>
       </div>
      </div>
     </div> -->
     <div
      class="shadow overflow-hidden border-b border-gray-200 rounded-md my-5">
      <queue-detail-table-outbound-answered
       :enabled="enabled"
       :tableData="filteredData()"
       :tableKeys="tableDataHeaders"
       :tableDataExtensions="tableDataExtensions"
       :selectedElement="selectedElement"
       @sort="sort($event)" />
     </div>
    </div>
   </div>
  </div>
  <div v-else class="pt-20 text-center">
   <svg
    xmlns="http://www.w3.org/2000/svg"
    class="mx-auto h-12 w-12 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor">
    <path
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
   </svg>
   <h3 class="mt-2 text-lg font-medium text-gray-900">
    {{ $t("warning.noDataFoundTitle") }}
   </h3>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import QueueDetailTableOutboundAnswered from "../../components/PBX/QueueDetailTableOutboundAnswered.vue";
import QueueDetailTableOutboundOverview from "../../components/PBX/QueueDetailTableOutboundOverview.vue";
import PbxDashboardOutbound from "../../components/PBX/PbxDashboardOutbound.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

//const buttonSelected = $t("dashboards.month");
export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
  PbxDashboardOutbound,
  QueueDetailTableOutboundOverview,
  QueueDetailTableOutboundAnswered,
 },
 data() {
  return {
   search: "",
   currentSort: "extension",
   currentSortDir: "asc",
   timeRange: 20,
   timeGap: 10,
   numberOfColumns: 4,
   selectedElement: null,
   tableData: {},
   tableDataHeaders: [],
   tableDataExtensions: [],
   extensionsLabels: [],
   extensionsData: {},
   elementLevelData: {},
   elementLevelDataRange: {},
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: this.$t("dashboards.month"),
   textMonth: "",
   data: undefined,
   showDetail: false,
   agentNumber: null,
   agentName: undefined,
  };
 },
 methods: {
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  // async getPbxData(stringOfElements, period, type) {
  //  console.log("file: ExpertStatisticsOutbound.vue:172 ~ getPbxData ~ period", period);
  //  try {
  //   const res = await axios.get(
  //    `${this.$cookie.getCookie("API")}/api/v1/pbxData/${this.hostName}/outbound?month=${period}`,
  //    {
  //     headers: {
  //      Authorizations: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //    }
  //   );
  //   console.log("file: ExpertStatistics.vue ~ line 90 ~ getPbxData ~ res.data", res.data);
  //   return res.data;
  //  } catch (error) {
  //   const status = error.response.status;
  //   this.$emit("setLoading", false);
  //   this.errorHandling(error);
  //   if (error.response.status === 500) {
  //    const msg = this.$t("error500");
  //    this.$router.push({
  //     name: "Error",
  //     query: { status, msg },
  //    });
  //   }
  //  }
  //  this.isLoading = false;
  // },
  async getPbxData(stringOfElements, sentPeriod, report) {
   let period = "";
   let start = "";
   let end = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.period;
   }
   if (period.length == 6) {
    start = this.parseDateDayMonthYearHifen(
     this.getFirstDayOfMonth(period.slice(0, 4), period.slice(-2))
    );
    end = this.parseDateDayMonthYearHifen(
     this.getLastDayOfMonth(period.slice(0, 4), period.slice(-2))
    );
   }
   if (period.length == 8) {
    start = this.parseDateDayMonthYearHifen(
     new Date(
      period.slice(0, 4),
      parseInt(period.slice(4, 6)) - 1,
      parseInt(period.slice(-2))
     )
    );
    end = start;
   }
   if (period.includes("-")) {
    start = this.parseDateDayMonthYearHifen(
     new Date(
      period.split("-")[0].slice(0, 4),
      parseInt(period.split("-")[0].slice(4, 6)) - 1,
      parseInt(period.split("-")[0].slice(-2))
     )
    );
    end = this.parseDateDayMonthYearHifen(
     new Date(
      period.split("-")[1].slice(0, 4),
      parseInt(period.split("-")[1].slice(4, 6)) - 1,
      parseInt(period.split("-")[1].slice(-2))
     )
    );
   }
   let type =
    report && report === "extension" ? 0 : report && report === "queue" ? 4 : 0;
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/outbound?start=${start}&end=${end}&dns=*&dn_type=${type}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    console.log("getPbxData ~ res.data:", res.data);
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async setCharts() {
   this.$store.dispatch("pbxElementType", this.urlType);

   let period = this.currentMonthAndYearForPbx;
   let monthPeriod = "";
   let type = undefined;
   this.dataPbxSeries = [];
   this.dataPbxLabels = [];
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;
   let res = undefined;

   if (this.periodSelector) {
    period = this.periodSelector;
    monthPeriod = this.periodSelector.slice(0, 6);
   }

   this.isLoading = true;
   if (this.pbxElements && this.pbxElements.length > 0) {
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });
   }
   arrayOfElements = [...new Set(arrayOfElements)];

   stringOfElements = String(arrayOfElements[0]);

   for (let index = 1; index < arrayOfElements.length; index++) {
    stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
   }

   type = this.urlType;

   res = await this.getPbxData(stringOfElements, period, type);
   this.data = res;

   let timeSelectedIsHour = false;
   let timeSelectedIsQuarter = false;

   if (this.timeSelected.length > 0) {
    if (
     this.timeSelected[0].slice(-2) === "00" &&
     this.timeSelected[1].slice(-2) === "00"
    ) {
     timeSelectedIsHour = true;
    } else {
     timeSelectedIsQuarter = true;
    }
   }
   //    if (period.length == 8) {
   //     this.setPbxChartsDay(res);
   //     this.setElementsChartsDay(res);
   //    } else {
   //     this.setPbxCharts(res);
   //     this.setElementsCharts(res);
   //    }

   this.setOutboundCallsData(res["all"], period);

   this.isLoading = false;
  },
  setOutboundCallsData(res, selectedPeriod) {
   let obj = {};
   let keys = [];
   let labels = [];
   let dataTypes = [];

   keys = Object.keys(res);
   let comparePeriod =
    selectedPeriod && selectedPeriod.length == 8
     ? "day"
     : selectedPeriod.includes("-")
     ? "custom"
     : "month";

   if (keys.length > 0) {
    let objSeries = {};
    objSeries["data"] = [];
    keys.forEach((key, index) => {
     let splitKey = key.split(":");
     let element = splitKey[2];
     let dataType = splitKey[4];
     if (dataTypes.indexOf(dataType) < 0) {
      dataTypes.push(dataType);
     }
     let extNumber = element;
     if (extNumber) {
      if (labels.indexOf(extNumber) < 0) {
       labels.push(extNumber);
      }
     }
    });
    let labelsLength = labels.length;
    dataTypes.forEach(type => {
     obj[type] = new Array(labelsLength).fill(0);
    });

    keys.forEach((key, index) => {
     let splitKey = key.split(":");
     let element = splitKey[2];
     let dataType = splitKey[4];
     let period = splitKey[0];
     let extNumber = element;
     let quarterHourlyElementSlice = period.slice(-4);
     let periodFlag = false;
     if (comparePeriod === "day") {
      periodFlag = period.slice(0, 8) === selectedPeriod;
     } else if (comparePeriod === "custom") {
      let splitedSelectedPeriod = selectedPeriod.split("-");
      let firstSelectedYear = splitedSelectedPeriod[0].slice(0, 4);
      let firstSelectedMonth = splitedSelectedPeriod[0].slice(4, 6);
      let firstSelectedDay = splitedSelectedPeriod[0].slice(6, 8);
      let secondSelectedYear = splitedSelectedPeriod[1].slice(0, 4);
      let secondSelectedMonth = splitedSelectedPeriod[1].slice(4, 6);
      let secondSelectedDay = splitedSelectedPeriod[1].slice(6, 8);
      let periodYear = period.slice(0, 4);
      let periodMonth = period.slice(4, 6);
      let periodDay = period.slice(6, 8);
      let firstSelectedPeriodDate = new Date(
       `${firstSelectedYear}-${firstSelectedMonth}-${firstSelectedDay}`
      ).setHours(0, 0, 0, 0);
      let secondSelectedPeriodDate = new Date(
       `${secondSelectedYear}-${secondSelectedMonth}-${secondSelectedDay}`
      ).setHours(0, 0, 0, 0);
      let periodDate = new Date(
       `${periodYear}-${periodMonth}-${periodDay}`
      ).setHours(0, 0, 0, 0);
      periodFlag =
       firstSelectedPeriodDate <= periodDate &&
       periodDate <= secondSelectedPeriodDate;
     } else {
      periodFlag = period.slice(0, 6) === selectedPeriod;
     }
     if (periodFlag) {
      if (period && period.length == 12) {
       if (this.timeSelected.length > 0) {
        let firsQuarterHour =
         (
          "0" +
          (parseInt(this.timeSelected[0].slice(0, 2)) +
           parseInt(this.hostTimeZone))
         ).slice(-2) + this.timeSelected[0].slice(-2);
        let lastQuarterHour =
         (
          "0" +
          (parseInt(this.timeSelected[1].slice(0, 2)) +
           parseInt(this.hostTimeZone))
         ).slice(-2) + this.timeSelected[1].slice(-2);
        if (labels.indexOf(extNumber) >= 0) {
         if (dataTypes.indexOf(dataType) >= 0) {
          if (
           parseInt(firsQuarterHour) <= parseInt(quarterHourlyElementSlice) &&
           parseInt(quarterHourlyElementSlice) < parseInt(lastQuarterHour)
          ) {
           if (obj[dataType][labels.indexOf(extNumber)]) {
            obj[dataType].splice(
             labels.indexOf(extNumber),
             1,
             parseInt(obj[dataType][labels.indexOf(extNumber)]) +
              parseInt(res[key])
            );
           } else {
            obj[dataType].splice(
             labels.indexOf(extNumber),
             1,
             parseInt(res[key])
            );
           }
          }
         }
        }
       } else {
        if (labels.indexOf(extNumber) >= 0) {
         if (dataTypes.indexOf(dataType) >= 0) {
          if (obj[dataType][labels.indexOf(extNumber)]) {
           obj[dataType].splice(
            labels.indexOf(extNumber),
            1,
            parseInt(obj[dataType][labels.indexOf(extNumber)]) +
             parseInt(res[key])
           );
          } else {
           obj[dataType].splice(
            labels.indexOf(extNumber),
            1,
            parseInt(res[key])
           );
          }
         }
        }
       }
      }
     }
    });
    this.setOutboundCallsTableData(obj, labels);
   }
  },
  setOutboundCallsTableData(data, labels) {
   console.log(
    "file: ExpertStatisticsOutbound.vue:270 ~ setOutboundCallsTableData ~ data",
    data
   );
   let slideTimeRange = parseInt(this.timeRange);
   let tableColumnNumber = parseInt(this.numberOfColumns);
   const timeGap = parseInt(this.timeGap);
   const maxTimeRange = slideTimeRange - timeGap + tableColumnNumber * timeGap;
   let dataType = "calls";
   let objTableData = {};
   let objKeys = Object.keys(data);
   for (let index = 0; index < tableColumnNumber; index++) {
    let arrAnsweredIn = [];
    let arrTalkingDuration = [];
    objKeys.forEach((key, index) => {
     if (slideTimeRange && key.includes("secs")) {
      let splitKey = key.split("_");
      if (key.includes("_")) {
       let keyFirstTimeRange = parseInt(splitKey[3]);
       let keySecondTimeRange = parseInt(splitKey[4]);
       let keyName = splitKey[0] + "_" + slideTimeRange;
       if (
        keySecondTimeRange <= slideTimeRange &&
        slideTimeRange < maxTimeRange
       ) {
        if (keyName.includes("answered")) {
         arrAnsweredIn = arrAnsweredIn.length
          ? arrAnsweredIn.map(function (num, idx) {
             return num + data[key][idx];
            })
          : data[key];
         objTableData[keyName] = arrAnsweredIn;
        }
        if (keyName.includes("talking")) {
         arrTalkingDuration = arrTalkingDuration.length
          ? arrTalkingDuration.map(function (num, idx) {
             return num + data[key][idx];
            })
          : data[key];
         objTableData[keyName] = arrTalkingDuration;
        }
       } else if (
        keySecondTimeRange > slideTimeRange &&
        slideTimeRange >= maxTimeRange
       ) {
        if (keyName.includes("answered")) {
         arrAnsweredIn = arrAnsweredIn.length
          ? arrAnsweredIn.map(function (num, idx) {
             return num + data[key][idx];
            })
          : data[key];
         objTableData[`${keyName}+`] = arrAnsweredIn;
        }
        if (keyName.includes("talking")) {
         arrTalkingDuration = arrTalkingDuration.length
          ? arrTalkingDuration.map(function (num, idx) {
             return num + data[key][idx];
            })
          : data[key];
         objTableData[`${keyName}+`] = arrTalkingDuration;
        }
       }
      }
     } else {
      objTableData[key] = data[key];
     }
    });
    slideTimeRange = slideTimeRange + timeGap;
   }
   //  this.tableData = objTableData;
   this.tableDataHeaders = Object.keys(objTableData).filter(key => {
    return key.includes("answered");
   });
   let headers = Object.keys(objTableData);
   this.tableDataExtensions = labels;
   this.setTableDataArray(objTableData, labels, headers);
  },
  setTableDataArray(dataObj, extensions, headers) {
   let obj = {};
   let arr = [];
   for (let index = 0; index < extensions.length; index++) {
    obj = {};
    headers.forEach((key, objIndex) => {
     const element = extensions[index];
     obj["extension"] = element;
     obj["agent"] =
      this.pbxMap && this.pbxMap["extensions"]
       ? this.pbxMap["extensions"][element]
       : null;
     obj["avg_talking_duration"] = parseInt(dataObj["answered"][index])
      ? parseInt(dataObj["talking"][index]) /
        parseInt(dataObj["answered"][index])
      : 0;
     obj["avg_ringing_duration"] = parseInt(dataObj["calls"][index])
      ? parseInt(dataObj["answering"][index]) /
        parseInt(dataObj["calls"][index])
      : 0;
     obj["unanswered"] =
      parseInt(dataObj["calls"][index]) - parseInt(dataObj["answered"][index]);
     obj[key] = dataObj[key][index];
    });
    arr.push(obj);
   }
   console.log(
    "file: ExpertStatisticsOutbound.vue:413 ~ headers.forEach ~ arr",
    arr
   );
   this.tableData = arr;
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedData() {
   return this.tableData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "totalWithoutTax" || toBeSorted === "totalWithTax") {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
      return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  filteredData() {
   return this.sortedData().filter(data => {
    if (this.pbxElements) {
     return this.pbxElements.indexOf(data.extension) >= 0;
    } else {
     return data;
    }
   });
  },
 },
 watch: {
  urlType: function (value) {
   this.setCharts();
  },
  waitingTimeRange: function (value) {
   this.setCharts();
  },
  periodSelector: function (val) {
   this.setCharts();
  },
  timeSelected: function (val) {
   this.setCharts();
   //    this.getDataFromElement(true);
  },
  timeRange: function (val) {
   this.setCharts();
  },
  timeGap: function (val) {
   this.setCharts();
  },
  numberOfColumns: function (val) {
   this.setCharts();
  },
 },
 mounted() {
  if (!this.periodSelector && !this.pbxPeriodSelected) {
   this.periodSelector = this.currentMonthAndYearForPbx;
  }
  if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.pbxElementType !== this.urlType)
   this.$store.dispatch("pbxElements", null);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
